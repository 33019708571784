<template>
    <div class="hero" >
        <div class="container h-100">
            <div class="row h-100 justify-content-center align-items-center">
                <div class="col-md-5">
                    <div class="hero-content">
                        <h4 class="text-center">
                          Bem-vindo(a) ao <b>SisLife</b> – O Sistema Definitivo para a Gestão de Igrejas
                        </h4>
                        <p class="text-center">
                          O SisLife foi criado com um propósito claro: auxiliar líderes e administradores dedicados na gestão eficiente da obra do Senhor. Com uma interface intuitiva e ferramentas poderosas, o SisLife coloca tudo o que você precisa ao seu alcance, facilitando o cuidado com os membros, a administração financeira da tesouraria, a emissão de carteirinhas, e muito mais.
                        </p>
                        <div class="hero-btn mt-5 text-center">
                            <button class="btn custom-btn btn-info mr-4" data-toggle="modal" data-target="#start">Entrar</button>
                            <a href="https://wa.me/556183661622" class="btn btn-xs btn-outline-secondary" target="_blank"><small>Entrar em contato</small></a>
                        </div>
                    </div>

                </div>
                <div class="col-md-7 hidden-xs-up">
                    <div class="graphic">
                        <img src="/img/svg/graphic.svg" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "Hero",
  props: {
    msg: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .hero {
        padding-top: 7rem;
        width: 100%;

        text-align: left;
    }

</style>
